import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import { FaTrashAlt, FaEllipsisV } from 'react-icons/fa';
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoIosInformationCircleOutline, IoIosAdd } from 'react-icons/io';
import { TiDeleteOutline } from 'react-icons/ti';
import { MdOutlineDelete, MdOutlineFileUpload, MdOutlineFileDownload } from 'react-icons/md';
import { GrPrevious, GrNext } from 'react-icons/gr';
import { BiArrowBack } from 'react-icons/bi';
import { BsCollection } from 'react-icons/bs';
import { CgOptions, CgSpinner } from 'react-icons/cg';
import { RxCross2 } from 'react-icons/rx';
import Sidebar from './AdminSidebar';
import DocumentOptionsPopup from './DocumentOptionsPopup';

function FolderManagement() {
  const [folders, setFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState(JSON.parse(localStorage.getItem('uploadingDocuments')) || []);
  const [files, setFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [error, setError] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [role, setRole] = useState('admin');
  const [activeMenu, setActiveMenu] = useState(null);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [showDocumentInfo, setShowDocumentInfo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showOptionsPopup, setShowOptionsPopup] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [showCreateFolderPopup, setShowCreateFolderPopup] = useState(false);
  const [showUploadDocumentPopup, setShowUploadDocumentPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [folderInfo, setFolderInfo] = useState({});
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isOptionLoading, setIsOptionLoading] = useState(false);
  const [metadata, setMetadata] = useState([]);

  const navigate = useNavigate();
  const { folderId } = useParams();
  const menuRef = useRef(null);
  const popupRef = useRef(null);
  const optionsPopupRef = useRef(null);
  const documentsPerPage = 10;
  const uploadPopupRef = useRef(null);
  const [showFolderInfo, setShowFolderInfo] = useState(null);
  const folderPopupRef = useRef(null);


  const handleMoreFolderInfo = (folder) => {
    setShowFolderInfo(folder);
  };

  const closeMoreFolderInfo = () => {
    setShowFolderInfo(null);
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    setRejectedFiles(rejectedFiles);
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    }
  });

  useEffect(() => {
    const fetchFolders = async () => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/login');
      } else {
        setIsLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/folders`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setFolders(response.data);
        } catch (error) {
          console.error('Error fetching folders:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchFolders();
  }, [navigate]);

  useEffect(() => {
    if (documents.length > 0) {
      const uniqueFolderIds = [...new Set(documents.flatMap(doc => doc.parent_id || []))];
      uniqueFolderIds.forEach(folderId => {
        if (!folderInfo[folderId]) {
          fetchFolderInfo(folderId);
        }
      });
    }
  }, [documents]);

  useEffect(() => {
    const savedSidebarState = localStorage.getItem('chatSidebarOpen');
    if (savedSidebarState !== null) {
      setSidebarOpen(JSON.parse(savedSidebarState));
    }
  }, []);

  useEffect(() => {
    if (folderId === 'all') {
      fetchAllDocuments();
    } else if (folderId) {
      fetchDocuments(folderId);
    }
  }, [folderId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowDocumentInfo(null);
      }
      if (folderPopupRef.current && !folderPopupRef.current.contains(event.target)) {
        closeMoreFolderInfo();
      }
      if (optionsPopupRef.current && !optionsPopupRef.current.contains(event.target)) {
        setShowOptionsPopup(false);
        if (folderId) fetchDocuments(folderId);
      }
      if (uploadPopupRef.current && !uploadPopupRef.current.contains(event.target)) {
        closeUploadPopup();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [folderId]);
  

  useEffect(() => {
    localStorage.setItem('uploadingDocuments', JSON.stringify(uploadingDocuments));
  }, [uploadingDocuments]);

  const fetchDocuments = async (folderId) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/${folderId}/documents`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setDocuments(response.data);
        setUploadingDocuments(prevDocs =>
          prevDocs.filter(placeholder => !response.data.some(doc => doc.name === placeholder.name))
        );
      } catch (error) {
        console.error('Error fetching documents:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchFolderInfo = async (folderId) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/folders/${folderId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFolderInfo(prevInfo => ({ ...prevInfo, [folderId]: response.data }));
      } catch (error) {
        console.error('Error fetching folder info:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  const fetchAllDocuments = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/document/all`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setDocuments(response.data);
        setUploadingDocuments(prevDocs =>
          prevDocs.filter(placeholder => !response.data.some(doc => doc.name === placeholder.name))
        );
      } catch (error) {
        console.error('Error fetching all documents:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCreateFolder = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/folders`, {
          name: folderName,
          metadata
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setFolders([...folders, response.data]);
        setFolderName('');
        setMetadata([]);
        setShowCreateFolderPopup(false);
      } catch (error) {
        console.error('Error creating folder:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  const handleUploadDocument = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
      return;
    }

    if (files.length === 0) {
      setError('Please select files to upload');
      return;
    }

    const invalidFiles = files.filter(file => !file.name.toLowerCase().endsWith('.docx'));
    if (invalidFiles.length > 0) {
      setError('Only .docx files areallowed');
      return;
    }

    setShowUploadDocumentPopup(false);
    navigate('/admin/all');

    const newUploadingDocuments = files.map(file => ({
      name: file.name,
      status: 'uploading',
      progress: 0
    }));
    setUploadingDocuments(prevDocs => [...prevDocs, ...newUploadingDocuments]);

    for (const file of files) {
      const formData = new FormData();
      formData.append('files', file, file.name);
      formData.append('folder_ids', selectedFolders.join(','));
      formData.append('metadatas', JSON.stringify(metadata));

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/document/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'accept': 'application/json'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadingDocuments(prevDocs => prevDocs.map(doc => 
              doc.name === file.name ? { ...doc, progress: percentCompleted } : doc
            ));
          }
        });

        const newDocument = response.data[0];

        setDocuments(prevDocs => [...prevDocs, newDocument]);
        setUploadingDocuments(prevDocs => prevDocs.filter(doc => doc.name !== file.name));

      } catch (error) {
        console.error('Error uploading document:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        setUploadingDocuments(prevDocs => prevDocs.map(doc => 
          doc.name === file.name ? { ...doc, status: 'error' } : doc
        ));
      }
    }

    setFiles([]);
    setSelectedFolders([]);
    setMetadata([]);
    setError(null);
  };

  const handleRemoveFailedUpload = (fileName) => {
    setUploadingDocuments(prevDocs => prevDocs.filter(doc => doc.name !== fileName));
  };

  const handleDeleteDocument = async (documentIds) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoading(true);
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/document/delete`, {
          headers: { Authorization: `Bearer ${token}` },
          data: documentIds
        });
        setDocuments(documents.filter(doc => !documentIds.includes(doc._id)));
        setSelectedDocuments([]);
      } catch (error) {
        console.error('Error deleting document:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteFolder = async (folderId) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoading(true);
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/folders?folder_id=${folderId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFolders(folders.filter(folder => folder._id !== folderId));
        setActiveMenu(null);
      } catch (error) {
        console.error('Error deleting folder:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('chatSidebarOpen', JSON.stringify(!sidebarOpen));
  };

  const handleFolderClick = (folderId) => {
    navigate(`/admin/${folderId}`);
  };

  const toggleMenu = (documentId) => {
    setActiveMenu(activeMenu === documentId ? null : documentId);
  };

  const handleCheckboxChange = (id, type) => {
    if (type === 'document') {
      if (selectedDocuments.includes(id)) {
        setSelectedDocuments(selectedDocuments.filter(docId => docId !== id));
      } else {
        setSelectedDocuments([...selectedDocuments, id]);
      }
    } else if (type === 'folder') {
      if (selectedFolders.includes(id)) {
        setSelectedFolders(selectedFolders.filter(folderId => folderId !== id));
      } else {
        setSelectedFolders([...selectedFolders, id]);
      }
    }
  };

  const handleSelectAll = () => {
    if (selectedDocuments.length === currentDocuments.length) {
      setSelectedDocuments([]);
    } else {
      setSelectedDocuments(currentDocuments.map(doc => doc._id));
    }
  };

  const handleDownloadDocument = async (documentId) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/document/download?document_id=${documentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/json'
          },
        });
        console.log('Download URL:', response.data);
        const downloadUrl = response.data;
        window.open(downloadUrl, '_blank');
      } catch (error) {
        console.error('Error downloading document:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  const closeUploadPopup = () => {
    setShowUploadDocumentPopup(false);
    setFiles([]);
    setSelectedFolders([]);
    setMetadata([]);
    setError(null);
  };

  const handlePurgeDocument = async (documentIds) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoading(true);
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/document/purge`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: documentIds
        });
        setDocuments(documents.filter(doc => !documentIds.includes(doc._id)));
        setSelectedDocuments([]);
      } catch (error) {
        console.error('Error purging document:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleMoreInfo = (document) => {
    setShowDocumentInfo(document);
  };

  const closeMoreInfo = () => {
    setShowDocumentInfo(null);
  };

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  const handleShowOptions = (documentId) => {
    setCurrentDocumentId(documentId);
    setShowOptionsPopup(true);
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const currentDocuments = [...documents, ...uploadingDocuments]
    .filter(doc => folderId === 'all' || (doc.parent_id && doc.parent_id.includes(folderId)))
    .slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage);

  const totalPages = Math.ceil([...documents, ...uploadingDocuments].length / documentsPerPage);

  const addMetadataRow = () => {
    setMetadata([...metadata, { name: '', value: '' }]);
  };

  const updateMetadata = (index, key, value) => {
    const newMetadata = [...metadata];
    newMetadata[index][key] = value;
    setMetadata(newMetadata);
  };

  const removeMetadataRow = (index) => {
    setMetadata(metadata.filter((_, i) => i !== index));
  };

  return (
    <div className="flex h-screen overflow-hidden font-sans bg-gray-50">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        handleLogout={handleLogout}
        role={role}
      />
      <div className={`flex-grow p-6 transition-all duration-300 ${sidebarOpen ? 'ml-64' : 'ml-20'} overflow-auto`}>
        <div className="max-w-2xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-600 mb-4 font-sans">Gestion des Collections et des Documents</h2>
          {!folderId && (
            <div>
            <div className="flex items-center mb-6 hover:text-hxmBlue-700">
                <BiArrowBack className="text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/dashboard')} />
                <span className="ml-2 text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/dashboard')}>Retour dashboard admin</span>
              </div>
              <div className="flex justify-end mb-6 space-x-4">
                <button
                  onClick={() => setShowCreateFolderPopup(true)}
                  className="border-2 border-hxmBlue-500 text-hxmBlue-500 p-1 rounded-lg hover:bg-hxmBlue-100 transition-colors duration-300"
                >
                  <IoIosAdd className="inline-block mr-2" /> Ajouter Collection
                </button>
                <button
                  onClick={() => setShowUploadDocumentPopup(true)}
                  className="bg-hxmBlue-500 text-white p-1 rounded-lg hover:bg-hxmBlue-700 transition-colors duration-300"
                >
                  <MdOutlineFileUpload className="inline-block mr-2" /> Télécharger
                </button>
              </div>
              <div className="mb-6">
                <ul className="mt-2 space-y-2">
                  <li className="p-2 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300">
                    <div className="flex items-center" onClick={() => handleFolderClick('all')}>
                      <BsCollection className="mr-2 text-hxmBlue-500" />
                      <span>All</span>
                    </div>
                  </li>
                  {folders.map(folder => (
                    <li key={folder._id} className="p-2 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300">
                      <div className="flex items-center" onClick={() => handleFolderClick(folder._id)}>
                        <BsCollection className="mr-2 text-hxmBlue-500" />
                        <span>{folder.name}</span>
                      </div>
                      <div className="relative">
                        <FaEllipsisV className="cursor-pointer" onClick={() => toggleMenu(folder._id)} />
                        {activeMenu === folder._id && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50" ref={menuRef}>
                        <button className="w-full text-left px-4 py-2 text-sm text-hxmBlue-500 hover:bg-gray-100" onClick={() => handleDeleteFolder(folder._id)}>
                          <FaTrashAlt className="mr-2" /> Supprimer
                        </button>
                        <button className="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-100" onClick={() => handleMoreFolderInfo(folder)}>
                          <IoIosInformationCircleOutline className="mr-2" /> Info
                        </button>
                        </div>
                      
                        )}
                      </div>
                    </li>
                  ))}
                  {showFolderInfo && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={folderPopupRef}>
                        <RxCross2 className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmPink-500" onClick={closeMoreFolderInfo} />
                        <h2 className="text-xl font-bold mb-4">Informations sur le dossier</h2>
                        <p><strong>ID:</strong> {showFolderInfo._id}</p>
                        <p><strong>Name:</strong> {showFolderInfo.name}</p>
                        <h3 className="text-lg font-medium text-gray-700 mt-4">Metadonnées:</h3>
                        {showFolderInfo.metadata && showFolderInfo.metadata.length > 0 ? (
                          <ul>
                            {showFolderInfo.metadata.map((meta, index) => (
                              <li key={index}>
                                <strong>{meta.name}:</strong> {meta.value}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-gray-600">Aucune métadonnée</p>
                        )}
                      </div>
                    </div>
                  )}

                </ul>
              </div>
            </div>
          )}

          {folderId && (
            <>
              <div className="flex items-center mb-6 hover:text-hxmBlue-700">
                <BiArrowBack className="text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/folders')} />
                <span className="ml-2 text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/folders')}>Retour page admin</span>
              </div>
              <div className="flex items-center mb-5">
                <BsCollection className="mr-4 text-2xl text-hxmBlue-500" />
                <h3 className="text-3xl font-medium text-gray-600">{folderId === 'all' ? 'Tous les documents' : folders.find(folder => folder._id === folderId)?.name}</h3>
              </div>
              {currentDocuments.length === 0 ? (
                <p className="text-center text-gray-600">Aucun document dans la collection</p>
              ) : (
                <>
                  <div className="p-2 bg-gray-100 rounded-lg flex justify-between items-center font-bold">
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedDocuments.length === currentDocuments.length}
                      className="w-1/6"
                    />
                    <span className="w-2/6">Nom</span>
                    <span className="w-1/6">Date </span>
                    <span className="w-1/6">Collections</span>
                    <span className="w-0/6">Actions</span>
                  </div>
                  <ul className="mt-2 space-y-2">
                    {currentDocuments.map(doc => (
                      <li key={doc._id || doc.name} className={`p-2 bg-gray-100 rounded-lg flex justify-between items-center ${selectedDocuments.includes(doc._id) ? 'border-2 border-hxmBlue-500' : ''} ${doc.status === 'uploading' ? 'opacity-50' : ''}`}>
                        <input
                          type="checkbox"
                          checked={selectedDocuments.includes(doc._id)}
                          onChange={() => handleCheckboxChange(doc._id, 'document')}
                          className="w-2/6"
                          disabled={doc.status === 'uploading'}
                        />
                        <div className="flex items-center w-2/6 relative">
                          <IoDocumentTextOutline className="mr-2 text-3xl text-hxmBlue-500" />
                          <span className='text-sm'>{doc.name}</span>
                          {doc.status === 'uploading' && (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <CgSpinner className="w-6 h-6 text-hxmBlue-500 animate-spin" />
                            </div>
                          )}
                          {doc.status === 'error' && (
                            <div className="flex items-center">
                              <span className="text-red-500 ml-2">Erreur</span>
                              <TiDeleteOutline
                                className="text-xl ml-2 text-red-500 cursor-pointer"
                                onClick={() => handleRemoveFailedUpload(doc.name)}
                              />
                            </div>
                          )}
                        </div>
                        <span className="w-1/6 text-sm">{doc.created_at ? formatDate(doc.created_at) : 'Uploading...'}</span>
                        <span className="w-1/6 text-sm">
                          {doc.parent_id ? 
                            (doc.parent_id.length === 1 ? 
                              (folderInfo[doc.parent_id[0]] ? folderInfo[doc.parent_id[0]].name : 'Loading...') : 
                              `in ${doc.parent_id.length} ${doc.parent_id.length > 1 ? 'collections' : 'collection'}`) : 
                            'Uploading...'}
                        </span>
                        <div className="w-0/6 flex justify-end items-center relative">
                          {doc.status !== 'uploading' && (
                            <>
                              <MdOutlineFileDownload className="mr-2 cursor-pointer text-gray-600 hover:text-hxmPink-500" onClick={() => handleDownloadDocument(doc._id)} />
                              <FaEllipsisV className="cursor-pointer hover:text-hxmPink-500" onClick={() => toggleMenu(doc._id)} />
                            </>
                          )}
                          {activeMenu === doc._id && (
                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50" ref={menuRef}>
                              <button className="w-full text-left px-4 py-2 text-sm text-hxmBlue-500 hover:bg-gray-100" onClick={() => handleDeleteDocument([doc._id])}>
                                <TiDeleteOutline className="mr-2" /> Supprimer
                              </button>
                              <button className="w-full text-left px-4 py-2 text-sm text-hxmBlue-500 hover:bg-gray-100" onClick={() => handlePurgeDocument([doc._id])}>
                                <MdOutlineDelete className="mr-2" /> Purger
                              </button>
                              <button className="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-100" onClick={() => handleMoreInfo(doc)}>
                                <IoIosInformationCircleOutline className="mr-2" /> Info
                              </button>
                              <button className="w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-100" onClick={() => handleShowOptions(doc._id)}>
                                <CgOptions className="mr-2" /> Options
                              </button>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="px-4 py-2 mx-1 rounded-lg disabled:opacity-50 hover:text-hxmPink-500"
                    >
                      <GrPrevious />
                    </button>
                    <span className="px-4 py-2 mx-1">{currentPage} of {totalPages}</span>
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="px-4 py-2 mx-1 rounded-lg disabled:opacity-50 hover:text-hxmPink-500"
                    >
                      <GrNext />
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {showDocumentInfo && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={popupRef}>
                <RxCross2 className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmPink-500" onClick={closeMoreInfo} />
                <h2 className="text-xl font-bold mb-4">Informations sur le document</h2>
                <p><strong>ID:</strong> {showDocumentInfo._id}</p>
                <p><strong>Name:</strong> {showDocumentInfo.name}</p>
                <p><strong>Created At:</strong> {formatDate(showDocumentInfo.created_at)}</p>
                <p><strong>On S3:</strong> {showDocumentInfo.onS3.toString()}</p>
                <p><strong>Collections:</strong></p>
                <ul>
                  {showDocumentInfo.parent_id && showDocumentInfo.parent_id.map(folderId => (
                    <li key={folderId}>{folderInfo[folderId] ? folderInfo[folderId].name : 'Loading...'}</li>
                  ))}
                </ul>
                <h3 className="text-lg font-medium text-gray-700 mt-4">Metadonnées:</h3>
                {showDocumentInfo.metadata && showDocumentInfo.metadata.length > 0 ? (
                  <ul>
                    {showDocumentInfo.metadata.map((meta, index) => (
                      <li key={index}>
                        <strong>{meta.name}:</strong> {meta.value}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-600">Aucune métadonnée</p>
                )}
              </div>
            </div>
          )}


          {selectedDocuments.length > 0 && (
            <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white shadow-lg p-4 rounded-lg flex justify-center z-50">
              <span className="mr-4 text-gray-800 font-medium">
                {selectedDocuments.length === 1
                  ? "1 élément selectionné"
                  : `${selectedDocuments.length} éléments selectionnés`}
              </span>
              <button className="mr-4 text-hxmBlue-500 hover:text-hxmBlue-800 transition-colors duration-300" onClick={() => handleDeleteDocument(selectedDocuments)}>
                <TiDeleteOutline className="inline-block mr-2" /> Supprimer
              </button>
              <button className="mr-4 text-hxmBlue-500 hover:text-hxmBlue-800 transition-colors duration-300" onClick={() => handlePurgeDocument(selectedDocuments)}>
                <MdOutlineDelete className="inline-block mr-2" /> Purger
              </button>
              <button className="mr-4 text-gray-600 hover:text-gray-800 transition-colors duration-300" onClick={() => selectedDocuments.forEach(handleDownloadDocument)}>
                <MdOutlineFileDownload className="inline-block mr-2" /> Télécharger
              </button>
              {selectedDocuments.length === 1 && (
                <button className="text-gray-600 hover:text-gray-800 transition-colors duration-300" onClick={() => handleShowOptions(selectedDocuments[0])}>
                  <CgOptions className="inline-block mr-2" /> Options
                </button>
              )}
            </div>
          )}

          {showOptionsPopup && (
            <DocumentOptionsPopup
              documentId={currentDocumentId}
              onClose={() => {
                setShowOptionsPopup(false);
                if (folderId) fetchDocuments(folderId);
              }}
              setIsOptionLoading={setIsOptionLoading}
            />
          )}

          {showCreateFolderPopup && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={popupRef}>
                <RxCross2 className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmBlue-500" onClick={() => setShowCreateFolderPopup(false)} />
                <h2 className="text-xl font-bold mb-4">Créer une nouvelle collection</h2>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Nom"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                />
                <h3 className="text-lg font-medium text-gray-700 mt-4">Metadonnées (optionnelles)</h3>
                {metadata.map((meta, index) => (
                  <div key={index} className="flex mb-2 rounded-lg ">
                    <input
                      type="text"
                      className="w-1/2 p-2  bg-gray-100  rounded-lg focus:outline-none focus:ring-2 focus:ring-hxmPink-500 mr-2 drop-shadow-md"
                      placeholder="Nom"
                      value={meta.name}
                      onChange={(e) => updateMetadata(index, 'name', e.target.value)}
                    />
                    <input
                      type="text"
                      className="w-1/2 p-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-hxmPink-500 drop-shadow-md"
                      placeholder="Valeur"
                      value={meta.value}
                      onChange={(e) => updateMetadata(index, 'value', e.target.value)}
                    />
                    <button className="ml-2 text-gray-500 hover:text-hxmPink-500" onClick={() => removeMetadataRow(index)}>
                      <TiDeleteOutline />
                    </button>
                  </div>
                ))}
                <button className="w-full bg-gray-100 text-gray-700 p-2 rounded-lg hover:bg-hxmBlue-200 transition-colors duration-300 mt-2" onClick={addMetadataRow}>
                  <IoIosAdd className="inline-block mr-2" /> Ajouter Metadonnée
                </button>
                <button onClick={handleCreateFolder} className="mt-2 w-full bg-hxmBlue-500 text-white p-2 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300">
                  Ajouter
                </button>
              </div>
            </div>
          )}

          {showUploadDocumentPopup && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={uploadPopupRef}>
                <RxCross2 
                  className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmBlue-500" 
                  onClick={closeUploadPopup}
                />
                <h2 className="text-xl font-bold mb-4">Télécharger Document</h2>
                <div className="mb-4 dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="w-full p-2 text-center text-gray-500 italic">
                    Déposez les fichiers .docx ici, ou cliquez pour sélectionner les fichiers
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="text-lg font-medium text-gray-700">Fichiers sélectionnés</h3>
                  <ul className="list-disc pl-5">
                    {files.map(file => (
                      <li key={file.name} className="text-gray-600">{file.name}</li>
                    ))}
                  </ul>
                </div>
                {rejectedFiles.length > 0 && (
                  <div className="mb-4">
                    <h3 className="text-lg font-medium text-red-700">Fichiers rejetés</h3>
                    <ul className="list-disc pl-5">
                      {rejectedFiles.map(file => (
                        <li key={file.file.name} className="text-red-600">
                          {file.file.name} - {file.errors[0].message}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="mb-4">
                  <h3 className="text-lg font-medium text-gray-700">Sélectionner une collection (optionnel)</h3>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    {folders.filter(folder => folder.name !== 'all' && folder.name !== 'Default').map(folder => (
                      <div
                        key={folder._id}
                        className={`p-4 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer ${selectedFolders.includes(folder._id) ? 'border-2 border-hxmBlue-500' : ''}`}
                        onClick={() => handleCheckboxChange(folder._id, 'folder')}
                      >
                        <span>{folder.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <h3 className="text-lg font-medium text-gray-700 mt-4">Metadonnées (optionnelles)</h3>
                {metadata.map((meta, index) => (
                  <div key={index} className="flex mb-2 rounded-lg ">
                    <input
                      type="text"
                      className="w-1/2 p-2  bg-gray-100  rounded-lg focus:outline-none focus:ring-2 focus:ring-hxmPink-500 mr-2 drop-shadow-md"
                      placeholder="Nom"
                      value={meta.name}
                      onChange={(e) => updateMetadata(index, 'name', e.target.value)}
                    />
                    <input
                      type="text"
                      className="w-1/2 p-2 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-hxmPink-500 drop-shadow-md"
                      placeholder="Valeur"
                      value={meta.value}
                      onChange={(e) => updateMetadata(index, 'value', e.target.value)}
                    />
                    <button className="ml-2 text-gray-500 hover:text-hxmPink-500" onClick={() => removeMetadataRow(index)}>
                      <TiDeleteOutline />
                    </button>
                  </div>
                ))}
                <button className="w-full bg-gray-100 text-gray-700 p-2 rounded-lg hover:bg-hxmBlue-200 transition-colors duration-300 mt-2" onClick={addMetadataRow}>
                  <IoIosAdd className="inline-block mr-2" /> Ajouter Metadonnée
                </button>
                <button onClick={handleUploadDocument} className="mt-2 w-full bg-hxmBlue-500 text-white p-2 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300">
                  Télécharger
                </button>
              </div>
            </div>
          )}

          {(isLoading || isOptionLoading) && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-25">
              <CgSpinner className="w-6 h-6 text-hxmBlue-500 animate-spin text-3xl" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FolderManagement;
