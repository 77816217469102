import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { RiExpandLeftLine, RiExpandRightLine, RiLogoutCircleLine, RiAddLine, RiExpandDiagonal2Fill, RiCollapseDiagonalFill } from 'react-icons/ri';
import { FaStopCircle } from "react-icons/fa";
import { BsArrowUpCircleFill, BsDatabase, BsChatText } from "react-icons/bs";
import { PiSpinnerLight } from "react-icons/pi";
import { IoFilterCircleOutline } from "react-icons/io5";
import { MdContentPaste } from "react-icons/md";
import { LiaDownloadSolid } from "react-icons/lia";
import { MdClose } from "react-icons/md";
import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";
import { IoIosInformationCircleOutline } from 'react-icons/io';
import logo from './icon/20240504_HXM_logo_with_name.png';
import { MdOutlineAdminPanelSettings } from "react-icons/md";

function Chat() {
  const [query, setQuery] = useState('');
  const [folders, setFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState(['All']);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState({});
  const [abortController, setAbortController] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isRechercheDocumentaire, setIsRechercheDocumentaire] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [visibleSourceIndices, setVisibleSourceIndices] = useState({});
  const [expandedSource, setExpandedSource] = useState(null);
  const [copyMessage, setCopyMessage] = useState('');
  const [feedbackPopupOpen, setFeedbackPopupOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [truncatedSources, setTruncatedSources] = useState({});
  const [username, setUsername] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const [currentSources, setCurrentSources] = useState([]);
  const [currentResponse, setCurrentResponse] = useState('');
  const [role, setRole] = useState(null);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [openMetadata, setOpenMetadata] = useState(null);
  const [availableMetadata, setAvailableMetadata] = useState({});
  const [selectedMetadata, setSelectedMetadata] = useState({});
  const [selectedMetadataFilters, setSelectedMetadataFilters] = useState({});
  const navigate = useNavigate();
  const endOfMessagesRef = useRef(null);
  const filterRef = useRef(null);
  const drawerRef = useRef(null);
  const expandedSourceRef = useRef(null);
  const feedbackPopupRef = useRef(null);
  const userInfoPopupRef = useRef(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/chat/folders`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          setFolders(response.data);
        })
        .catch(error => {
          handleTokenError(error);
          console.error('Error fetching data: ', error);
        });

      axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/role`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          setRole(response.data);
        })
        .catch(error => {
          console.error('Error fetching user role: ', error);
        });
    }
  }, [navigate]);

  useEffect(() => {
    const savedSidebarState = localStorage.getItem('chatSidebarOpen');
    if (savedSidebarState !== null) {
      setSidebarOpen(JSON.parse(savedSidebarState));
    }
  }, []);

  const handleTokenError = (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove('token');
      navigate('/login');
    } else {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchAvailableMetadata = async (folderId) => {
    const token = Cookies.get('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/${folderId}/documents`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const documents = response.data;
      const metadataMap = {};
      documents.forEach(doc => {
        doc.metadata.forEach(meta => {
          if (!metadataMap[meta.name]) {
            metadataMap[meta.name] = new Set();
          }
          metadataMap[meta.name].add(meta.value);
        });
      });
      Object.keys(metadataMap).forEach(key => {
        metadataMap[key] = Array.from(metadataMap[key]);
      });
      setAvailableMetadata(metadataMap);
      setSelectedMetadata({});
    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  };

  const handleFolderChange = (folder) => {
    setSelectedFolders((prevSelectedFolders) => {
      let updatedFolders;
      if (folder.name === "All") {
        updatedFolders = prevSelectedFolders.includes("All") ? [] : ["All"];
      } else {
        updatedFolders = prevSelectedFolders.includes(folder.name)
          ? prevSelectedFolders.filter((name) => name !== folder.name)
          : [...prevSelectedFolders.filter((name) => name !== "All"), folder.name];
      }

      if (updatedFolders.length === 1 && updatedFolders[0] !== "All") {
        const selectedFolder = folders.find(f => f.name === updatedFolders[0]);
        if (selectedFolder) {
          fetchAvailableMetadata(selectedFolder._id);
        }
      } else {
        setAvailableMetadata([]);
      }

      return updatedFolders;
    });
    setSelectedMetadataFilters({});
  };

  const handleMetadataChange = (metadataName, value, isSelected) => {
    setSelectedMetadata(prevState => {
      const newState = { ...prevState };
      if (isSelected) {
        newState[metadataName] = [...(newState[metadataName] || []), value];
      } else {
        newState[metadataName] = newState[metadataName]?.filter(v => v !== value) || [];
        if (newState[metadataName].length === 0) {
          delete newState[metadataName];
        }
      }
      return newState;
    });
    setOpenMetadata(null); // Close the dropdown after selection
  };

  const handleMetadataFilterChange = (metadataName, value) => {
    setSelectedMetadataFilters(prev => ({
      ...prev,
      [metadataName]: value
    }));
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSendQuery = async () => {
    if (!query) return;
  
    const messageId = Date.now();
  
    // Transform selectedMetadata into the correct format
    const formattedMetadata = Object.entries(selectedMetadata).map(([key, values]) => {
      const value = Array.isArray(values) && values.length === 1 ? values[0] : values;
      return [key, value];
    });
  
    const newMessage = { role: 'user', content: query, id: messageId, metadata: Object.fromEntries(formattedMetadata) };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setQuery('');
    scrollToBottom();
  
    setLoading(true);
    setLoadingResponse(prevState => ({ ...prevState, [messageId]: true }));
    const token = Cookies.get('token');
    const payload = {
      query,
      history: messages.filter(msg => msg.role === 'user' || msg.role === 'bot').map(msg => [msg.role, msg.content]),
      folders: selectedFolders.length > 0 ? selectedFolders : [],
      metadata: formattedMetadata  // Send metadata as a list of tuples
    };
  
    const controller = new AbortController();
    setAbortController(controller);
  
    try {
      const sourceResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/chat/get_sources`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        signal: controller.signal
      });
      const fetchedSources = sourceResponse.data;
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages, { role: 'sources', content: fetchedSources, id: messageId }];
        return updatedMessages;
      });
  
      setVisibleSourceIndices(prev => ({ ...prev, [messageId]: [] }));
  
      fetchedSources.forEach((_, idx) => {
        setTimeout(() => {
          setVisibleSourceIndices(prev => ({
            ...prev,
            [messageId]: [...prev[messageId], idx]
          }));
        }, idx * 200);
      });
  
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/chat/get_response`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        signal: controller.signal
      });
      const newBotMessage = { role: 'bot', content: response.data.response, id: messageId };
      setMessages(prevMessages => [...prevMessages, newBotMessage]);
      setLoadingResponse(prevState => ({ ...prevState, [messageId]: false }));
      scrollToBottom();
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request was cancelled', error.message);
      } else {
        handleTokenError(error);
        console.error('Error sending query due to:', error);
      }
    } finally {
      setLoading(false);
      setLoadingResponse(prevState => ({ ...prevState, [messageId]: false }));
      setAbortController(null);
    }
  };

  const handleSourceClick = async (objectName) => {
    const token = Cookies.get('token');
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/chat/get_presigned_url`, { object_name: objectName }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const url = response.data.url;
      window.open(url, '_blank');
    } catch (error) {
      handleTokenError(error);
      console.error('Error fetching presigned url due to:', error);
    }
  };

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  const handleReset = () => {
    setSelectedFolders([]);
    setIsRechercheDocumentaire(false);
    setMessages([]);
    setSelectedMetadataFilters({});
  };

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterOpen(false);
      }
      if (drawerRef.current && !drawerRef.current.contains(event.target) && !expandedSource) {
        setDrawerOpen(false);
      }
      if (expandedSourceRef.current && !expandedSourceRef.current.contains(event.target)) {
        setExpandedSource(null);
      }
      if (feedbackPopupRef.current && !feedbackPopupRef.current.contains(event.target)) {
        setFeedbackPopupOpen(false);
      }
      if (userInfoPopupRef.current && !userInfoPopupRef.current.contains(event.target)) {
        setShowUserInfo(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expandedSource, feedbackPopupOpen, showUserInfo]);

  const adjustDropdownPosition = () => {
    if (filterRef.current) {
      const rect = filterRef.current.getBoundingClientRect();
      const dropdownHeight = rect.height;
      const dropdownWidth = rect.width;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if (rect.bottom > windowHeight) {
        filterRef.current.style.top = `-${dropdownHeight}px`;
      } else {
        filterRef.current.style.top = `2rem`;
      }

      if (rect.right > windowWidth) {
        filterRef.current.style.left = `${windowWidth - dropdownWidth}px`;
      } else {
        filterRef.current.style.left = `0`;
      }
    }
  };

  useEffect(() => {
    adjustDropdownPosition();
  }, [filterOpen]);

  const handleStopGeneration = () => {
    if (abortController) {
      abortController.abort();
      setLoading(false);
      setAbortController(null);
    }
  };

  const handleExpandSource = (source) => {
    setExpandedSource(source);
  };

  const handleCollapseSource = () => {
    setExpandedSource(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCopyContent = (content) => {
    if (!navigator.clipboard) {
      console.error('Clipboard API not supported');
      setCopyMessage("Clipboard API not supported!");
      setTimeout(() => setCopyMessage(''), 2000);
      return;
    }

    navigator.clipboard.writeText(content).then(() => {
      setCopyMessage("Content copied to clipboard!");
      setTimeout(() => setCopyMessage(''), 2000);
    }).catch((err) => {
      console.error('Could not copy text: ', err);
      setCopyMessage("Failed to copy content!");
      setTimeout(() => setCopyMessage(''), 2000);
        });

    };

    const handleThumbDownClick = (messageId) => {
      setFeedbackPopupOpen(true);
      setCurrentMessageId(messageId);
    };
  
    const handleSendFeedback = async () => {
      const token = Cookies.get('token');
      const payload = {
        username: username,
        folders: selectedFolders,
        query: messages.find(msg => msg.id === currentMessageId)?.content || '',
        sources: currentSources,
        response: currentResponse,
        feedback: feedback,
        feedbacktype: feedbackType,
      };
    
      console.log('Feedback payload:', payload);
    
      try {
        setFeedbackLoading(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/chat/feedback`, payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setFeedback('');
        setFeedbackPopupOpen(false);
        setFeedbackType('');
        setCurrentSources([]);
        setCurrentResponse('');
      } catch (error) {
        handleTokenError(error);
        console.error('Error sending feedback due to:', error);
      } finally {
        setFeedbackLoading(false);
      }
    };
  
    const handleFeedbackClick = (messageId, feedbackType) => {
      const message = messages.find(msg => msg.id === messageId);
      const sources = messages.find(msg => msg.role === 'sources' && msg.id === messageId);
      const response = messages.find(msg => msg.role === 'bot' && msg.id === messageId);
      setCurrentMessageId(messageId);
      setFeedbackPopupOpen(true);
      setFeedbackType(feedbackType);
      setCurrentSources(sources ? sources.content : []);
      setCurrentResponse(response ? response.content : '');
    };
  
    const truncateText = (text, length) => {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    };
  
    const handleSidebarToggle = () => {
      setSidebarOpen(!sidebarOpen);
      localStorage.setItem('chatSidebarOpen', JSON.stringify(!sidebarOpen));
    };
  
    const handleUserInfoToggle = () => {
      setShowUserInfo(!showUserInfo);
    };
  
    return (
      <div className="flex h-screen overflow-hidden font-sans bg-gray-50">
        {/* Sidebar */}
        <div className={`fixed flex flex-col justify-between h-full transition-all duration-300 ${sidebarOpen ? 'w-64' : 'w-20'} bg-gray-200 shadow-inner`}>
          {/* Sidebar content */}
          <div className="p-4 flex flex-col items-start">
            <button onClick={handleSidebarToggle} className="text-gray-600">
              {sidebarOpen ? <RiExpandLeftLine className="text-3xl bg-gray-300 p-1 rounded-full hover:bg-hxmBlue-200 transition-colors duration-300" /> : <RiExpandRightLine className="text-3xl bg-gray-300 p-1 rounded-full hover:bg-hxmBlue-200 transition-colors duration-300" />}
            </button>
            {sidebarOpen && (
              <img src={logo} alt="Company Logo" className="my-5 w-32 transition-all duration-300" />
            )}
            {role === 'admin' && (
              <div className="flex items-center mt-4">
                <button onClick={() => navigate('/admin/dashboard')} className="text-gray-600 flex items-center font-semibold text-sm font-sans">
                  <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-hxmBlue-200 transition-colors duration-300"><MdOutlineAdminPanelSettings className="text-2xl" /></span>
                  {sidebarOpen && <span className='font-sans'>Administration</span>}
                </button>
              </div>
            )}
            <div className="flex items-center mt-4">
              <button onClick={handleReset} className="text-gray-600 flex items-center font-semibold text-sm font-sans">
                <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-hxmBlue-200 transition-colors duration-300"><RiAddLine className="text-2xl" /></span>
                {sidebarOpen && <span className='font-sans'>Nouveau chat</span>}
              </button>
            </div>
            <div className="flex items-center mt-4 relative">
              <button
                onClick={handleUserInfoToggle}
                onMouseEnter={handleUserInfoToggle}
                onMouseLeave={handleUserInfoToggle}
                className="text-gray-600 flex items-center font-semibold text-sm font-sans"
              >
                <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-hxmBlue-200 transition-colors duration-300"><IoIosInformationCircleOutline className="text-2xl" /></span>
                {sidebarOpen && <span className='font-sans'>Info</span>}
              </button>
              {showUserInfo && (
                <div ref={userInfoPopupRef} className="absolute left-full top-0 mt-2 ml-4 w-64 p-4 bg-white rounded-lg shadow-lg z-10">
                  <h3 className="text-lg font-bold mb-2">User Guide</h3>
                  <p>Posez toutes les questions sur vos documents et filtrez les documents sources par collection si nécessaire.</p>
                </div>
              )}
            </div>
          </div>
          <div className="p-4 flex items-center">
            <button onClick={handleLogout} className="text-gray-600 flex items-center font-semibold text-md font-sans">
              <span className="bg-gray-300 p-1 rounded-full mr-2 hover:bg-hxmBlue-200 transition-colors duration-300"><RiLogoutCircleLine className="text-2xl" /></span>
              {sidebarOpen && <span className='font-sans'>Logout</span>}
            </button>
          </div>
        </div>
  
        {/* Main content */}
        <div className={`flex-grow p-4 transition-all duration-300 ${sidebarOpen ? 'ml-64' : 'ml-20'} overflow-auto relative`}>
          <div className="mt-12 mb-8 mx-auto max-w-2xl text-center">
            {messages.length === 0 ? (
              <div className="text-center">
                <h1 className="text-hxmBlue-500 text-7xl font-bold font-sans">QUYD</h1>
                <h2 className="text-hxmBlue-500 text-lg font-bold font-sans">Questionnez vos documents</h2>
                <div className="flex flex-col items-center mt-4">
                  <img src={logo} alt="Hexamind Logo" className="w-40 h-40" />
                  <p className="text-md text-gray-700">Pour demo</p>
                </div>
              </div>
            ) : (
              messages.reduce((acc, msg, index) => {
                const lastMsg = acc[acc.length - 1];
                if (msg.role === 'user') {
                  acc.push({ query: msg.content, sources: [], response: '', loading: true, id: msg.id });
                } else if (msg.role === 'sources') {
                  lastMsg.sources = msg.content;
                } else if (msg.role === 'bot') {
                  lastMsg.response = msg.content;
                  lastMsg.loading = false;
                }
                return acc;
              }, []).map((block, index) => (
                <div key={index} className="mb-8 pb-8">
                  <h5 className={`font-medium mb-2 text-2xl font-sans text-gray-600 text-left`}>{block.query}</h5>
                  <div className="mb-4"></div>
                  <div className="flex items-center gap-4 mb-5">
                    <BsDatabase className="text-xl text-hxmPink-500" />
                    <span className="text-xl font-medium font-sans text-gray-600">Sources</span>
                  </div>
                  <div className="flex gap-2 mb-4">
                    {loadingResponse[block.id] && !block.sources.length ? (
                      <div className="p-2 flex gap-4 animate-pulse w-[70%] h-30`">
                        <div className="bg-gray-100 p-2 rounded-lg shadow-sm w-[70%] h-30"></div>
                        <div className="bg-gray-100 p-2 rounded-lg shadow-sm w-[70%] h-30"></div>
                        <div className="bg-gray-100 p-2 rounded-lg shadow-sm w-[70%] h-30"></div>
                        <div className="bg-gray-100 p-2 rounded-lg shadow-sm w-[70%] h-30"></div>
                      </div>
                    ) : (
                      <>
                        {block.sources && block.sources.slice(0, 3).map((source, idx) => (
                          <div key={idx} className={`relative bg-gray-100 p-2 rounded-lg shadow-sm cursor-pointer hover:bg-hxmBlue-200 transition-opacity duration-500 w-[70%] h-30 font-sans ${visibleSourceIndices[block.id] && visibleSourceIndices[block.id].includes(idx) ? 'opacity-100' : 'opacity-0'}`} onClick={() => handleExpandSource(source)}>
                            <h6 className="font-semibold text-xs font-sans text-gray-900 text-left">{source.document_title || 'No Title'}</h6>
                            <p className="text-xs font-sans text-gray-600 text-left">{truncateText(source.content, 100)}</p>
                            <button className="absolute bottom-2 right-2 text-gray-600 hover:text-hxmPink-500 transition-colors duration-300" onClick={(e) => { e.stopPropagation(); handleSourceClick(source.document_title); }}>
                              <LiaDownloadSolid className="text-md" />
                            </button>
                          </div>
                        ))}
                        {block.sources && block.sources.length > 3 && (
                          <div className={`bg-gray-100 p-2 rounded-lg shadow-sm cursor-pointer hover:bg-hxmBlue-200 transition-opacity duration-700 w-[70%] h-30 font-sans ${visibleSourceIndices[block.id] && visibleSourceIndices[block.id].length >= 3 ? 'opacity-100' : 'opacity-0'}`} onClick={handleDrawerToggle}>
                            <div className="flex items-center justify-center h-full">
                              <span className="text-hxmBlue-500 font-semibold text-xs font-sans">See {block.sources.length - 3} more</span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-4 mb-5">
                    {loadingResponse[block.id] ? (
                      <>
                        <PiSpinnerLight className="text-xl text-hxmPink-500 motion-safe:animate-spin" />
                        <span className="text-xl font-medium font-sans text-gray-600">Réponse</span>
                      </>
                    ) : (
                      <>
                        <BsChatText className="text-xl text-hxmPink-500" />
                        <span className="text-xl font-medium font-sans text-gray-600">Réponse</span>
                      </>
                    )}
                  </div>
                  {loadingResponse[block.id] ? (
                    <p className="text-sm whitespace-pre-wrap animate-pulse bg-gray-100 p-2 rounded-lg h-12 font-sans text-gray-600"></p>
                  ) : (
                    <div>
                      <p className="text-md whitespace-pre-wrap text-gray-900 text-left">{block.response}</p>
                      <div className="flex justify-between mt-2">
                        <div className="flex items-center">
                          <button className="flex items-center text-gray-600 hover:text-hxmPink-500 transition-colors duration-300" onClick={() => handleFeedbackClick(block.id, 'good')}>
                            <LuThumbsUp className="text-xl mr-2" />
                          </button>
                          <button className="flex items-center text-gray-600 hover:text-hxmPink-500 transition-colors duration-300" onClick={() => handleFeedbackClick(block.id, 'bad')}>
                            <LuThumbsDown className="text-xl mr-2" />
                          </button>
                        </div>
                        <button className="flex items-center text-gray-600 hover:text-hxmPink-500 transition-colors duration-300" onClick={() => handleCopyContent(block.response)}>
                          <MdContentPaste className="text-xl mr-1" />
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="my-4 border-b-2 border-gray-300"></div>
                </div>
              ))
            )}
          </div>
          <div ref={endOfMessagesRef} />
  
          <div className="fixed bottom-3 left-0 w-full transition-all duration-300" style={{ paddingLeft: sidebarOpen ? '16rem' : '5rem', paddingRight: '5rem' }}>
            {/* Metadata filter section */}
            {Object.entries(availableMetadata).length > 0 && (
              <div className="flex flex-wrap items-center gap-2 mb-2 max-w-2xl mx-auto">
                {/* Customize your search text */}
                <span className="text-sm text-gray-600">Customize your search:</span>
                {/* Metadata filter bubbles */}
                {Object.entries(availableMetadata).map(([metadataName, values]) => (
                  <div key={metadataName} className="relative">
                    <button
                      onClick={() => setOpenMetadata(openMetadata === metadataName ? null : metadataName)}
                      className="px-3 py-1 bg-gray-100 rounded-full text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hxmBlue-500"
                    >
                      {metadataName}
                    </button>
                    {openMetadata === metadataName && (
                      <div className="absolute z-10 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          {values.map((value) => (
                            <button
                              key={value}
                              onClick={() => {handleMetadataChange(metadataName, value, !selectedMetadata[metadataName]?.includes(value));
                                setOpenMetadata(null);
                              }}
                              className={`block w-full text-left px-4 py-2 text-sm ${
                                selectedMetadata[metadataName]?.includes(value)
                                  ? 'bg-hxmBlue-100 text-hxmBlue-900'
                                  : 'text-gray-700'
                              } hover:bg-gray-100`}
                              role="menuitem"
                            >
                              {value}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {Object.entries(selectedMetadata).map(([metadataName, values]) =>
                  values.map((value) => (
                    <div
                      key={`${metadataName}-${value}`}
                      className="bg-hxmBlue-100 text-hxmBlue-800 px-2 py-1 rounded-full text-sm flex items-center"
                    >
                      <span>{`${metadataName}: ${value}`}</span>
                      <button
                        onClick={() => handleMetadataChange(metadataName, value, false)}
                        className="ml-1 text-hxmBlue-500 hover:text-hxmBlue-700 focus:outline-none"
                      >
                        ×
                      </button>
                    </div>
                  ))
                )}
                {Object.keys(selectedMetadata).length > 0 && (
                  <button
                    onClick={() => setSelectedMetadata({})}
                    className="px-3 py-1 bg-gray-100 rounded-full text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none"
                  >
                    Clear all
                  </button>
                )}
              </div>
            )}
            
            {/* Chat input bar */}
            <div className="flex items-center bg-gray-200 bg-opacity-90 p-2 rounded-full shadow-lg border border-gray-300 max-w-2xl mx-auto transition-all duration-300 relative">
              <button onClick={() => setFilterOpen(!filterOpen)} className="flex items-center text-gray-600 font-semibold text-sm mr-2 relative hover:text-hxmPink-500 transition-colors duration-300 rounded-xl font-sans">
                <IoFilterCircleOutline className="text-lg font-sans" />
                <span className="ml-1">
                  {selectedFolders.includes("All") ? "All" : selectedFolders.length === 0 ? 'Collections' : selectedFolders.length === 1 ? selectedFolders[0] : `${selectedFolders.length} collections`}
                </span>
                {filterOpen && (
                  <div ref={filterRef} className="absolute top-full left-0 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg p-2 z-10 grid grid-cols-3 sm:grid-cols-4 gap-2 text-gray-600" style={{ minWidth: '500px' }}>
                    <div className="flex justify-between items-center w-full">
                      <button key="All" onClick={() => handleFolderChange({ name: "All" })} className={`p-3 hover:bg-hxmBlue-200 rounded-md text-sm w-full text-center overflow-hidden transition-colors duration-300 ${selectedFolders.includes("All") ? 'border-2 border-blue-500' : ''}`}>
                        All
                      </button>
                    </div>
                    {folders.map((folder, idx) => (
                      <button key={idx} onClick={() => handleFolderChange(folder)} className={`p-3 hover:bg-hxmBlue-200 rounded-md text-sm w-full text-center overflow-hidden transition-colors duration-300 ${selectedFolders.includes(folder.name) ? 'border-2 border-blue-500' : ''}`}>
                        {folder.name}
                      </button>
                    ))}
                  </div>
                )}
              </button>
              <input
                type="text"
                placeholder="Posez votre question ici"
                value={query}
                onChange={handleQueryChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSendQuery();
                    setQuery('');
                  }
                }}
                className="flex-grow p-2 bg-transparent outline-none"
              />
              <button onClick={loading ? handleStopGeneration : handleSendQuery} className="ml-2 p-2">
                {loading ? <FaStopCircle className="text-3xl text-hxmPink-500 font-sans" /> : <BsArrowUpCircleFill className={`text-3xl ${query ? 'text-hxmPink-500' : 'text-gray-300'}`} />}
              </button>
            </div>
          </div>
        </div>
        
        {/* Drawer for additional sources */}
        {drawerOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-end" onClick={() => setDrawerOpen(false)}>
            <div className="bg-white w-96 p-4 overflow-y-auto max-h-full relative" ref={drawerRef} onClick={(e) => e.stopPropagation()}>
              <button className="absolute top-2 right-2 text-gray-600 hover:text-hxmPink-500 rounded-xl transition-colors duration-300" onClick={() => setDrawerOpen(false)}>
                <MdClose className="text-2xl " />
              </button>
              <h6 className="font-bold font-sans text-gray-600 mb-4">{messages.reduce((acc, msg) => msg.role === 'sources' ? acc + msg.content.length : acc, 0)} Sources</h6>
              <div className="grid gap-4">
                {messages.filter(msg => msg.role === 'sources').flatMap(msg => msg.content).map((source, i) => (
                  <div key={i} className="relative bg-gray-100 p-4 rounded-lg shadow-sm cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300" onClick={() => handleExpandSource(source)}>
                    <h6 className="font-semibold text-md font-sans text-gray-900">{source.document_title || 'No Title'}</h6>
                    <p className="text-xs font-sans text-gray-600">{truncateText(source.content, 100)}</p>
                    <p className="text-sm font-sans text-gray-600 mt-2">{source.content ? truncateText(source.content, 100) : 'No content available'}</p>
                    <button className="absolute bottom-2 right-2 text-gray-600 hover:text-hxmPink-500 transition-colors duration-300 " onClick={(e) => { e.stopPropagation(); handleSourceClick(source.document_title); }}>
                      <LiaDownloadSolid className="text-lg" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
    
        {/* Expanded source view */}
        {expandedSource && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center" onClick={(e) => e.stopPropagation()}>
            <div className="bg-white p-6 rounded-lg shadow-lg overflow-y-auto max-h-full max-w-3xl relative transition-all duration-300 ease-in-out transform scale-100" ref={expandedSourceRef}>
              <button className="absolute top-2 right-2 text-gray-600 hover:text-hxmPink-500 transition-colors duration-300" onClick={(e) => { e.stopPropagation(); handleCollapseSource(); }}>
                <RiCollapseDiagonalFill className="text-xl" />
              </button>
              <button className="absolute bottom-2 right-2 text-gray-600 hover:text-hxmPink-500 transition-colors duration-300" onClick={(e) => { e.stopPropagation(); handleCopyContent(expandedSource.content); }}>
                <MdContentPaste className="text-xl" />
              </button>
              <h6 className="font-bold font-sans text-gray-600 mb-4 text-left">{expandedSource.document_title || 'No Title'}</h6>
              <p className="text-sm font-sans text-gray-600 text-left">{expandedSource.document_title}</p>
              <p className="text-md font-sans text-gray-900 mt-2 text-left">{expandedSource.content || 'No content available'}</p>
            </div>
          </div>
        )}
    
        {/* Copy message notification */}
        {copyMessage && (
          <div className="fixed bottom-5 right-5 bg-hxmBlue-500 text-gray-50 p-2 rounded-lg shadow-lg transition-opacity duration-300">
            {copyMessage}
          </div>
        )}
    
        {/* Feedback popup */}
        {feedbackPopupOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div ref={feedbackPopupRef} className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h6 className="font-bold font-sans text-gray-600 mb-4">{feedbackType === 'good' ? 'Pourquoi cette réponse est-elle satisfaisante ?' : 'Pourquoi cette réponse n\'est-elle pas satisfaisante ?'}</h6>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Nom</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Collections</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  value={selectedFolders.join(', ')}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Question</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  value={messages.find(msg => msg.id === currentMessageId)?.content || ''}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Metadata</label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {Object.entries(messages.find(msg => msg.id === currentMessageId)?.metadata || {}).map(([key, value]) => (
                    <div key={key} className="flex items-center bg-gray-100 px-2 py-1 rounded-full">
                      <span className="font-medium mr-1">{key}:</span>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => {
                          setMessages(prevMessages => 
                            prevMessages.map(msg => 
                              msg.id === currentMessageId 
                                ? { ...msg, metadata: { ...msg.metadata, [key]: e.target.value } }
                                : msg
                            )
                          );
                        }}
                        className="bg-transparent outline-none"
                      />
                    </div>
                  ))}
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="text"
                    placeholder="New metadata key"
                    className="p-2 border border-gray-300 rounded-lg mr-2"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && e.target.value) {
                        setMessages(prevMessages => 
                          prevMessages.map(msg => 
                            msg.id === currentMessageId 
                              ? { ...msg, metadata: { ...msg.metadata, [e.target.value]: '' } }
                              : msg
                          )
                        );
                        e.target.value = '';
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Sources</label>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  rows="3"
                  value={currentSources.map(source => source.document_title).join(', ')}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Réponse</label>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  rows="4"
                  value={currentResponse}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Feedback</label>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  rows="4"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </div>
              <div className="flex justify-end">
                <button className="bg-gray-300 text-gray-600 px-4 py-2 rounded-lg mr-2 hover:bg-gray-400 transition-colors duration-300" onClick={() => setFeedbackPopupOpen(false)}>Annuler</button>
                <button
                  className="bg-hxmBlue-500 text-gray-50 px-4 py-2 rounded-lg hover:bg-pink-700 transition-colors duration-300"
                  onClick={handleSendFeedback}
                  disabled={feedbackLoading}
                >
                  {feedbackLoading ? (
                    <PiSpinnerLight className="text-xl animate-spin" />
                  ) : (
                    'Envoyer'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default Chat;
