import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BiHomeAlt2 } from 'react-icons/bi';
import { CiLock, CiUnlock } from 'react-icons/ci';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('password', password);

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/token`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data && response.data.access_token) {
        const token = response.data.access_token;
        Cookies.set('token', token);
        setIsUnlocked(true);
        setTimeout(() => {
          navigate('/chat');
        }, 700); 
      } else {
        setError('Invalid response from server');
        triggerShake();
      }
    } catch (error) {
      console.error('Error logging in: ', error);
      setError('Invalid credentials');
      triggerShake();
    }
  };

  const triggerShake = () => {
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
    }, 500); // Duration of the shake effect
  };

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/chat');
    }
  }, [navigate]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
      <div className="w-full max-w-lg p-10 space-y-6 bg-white rounded-3xl shadow-md relative">
        <h2 className="text-3xl font-bold text-gray-800 text-center">Connectez-vous</h2>
        {error && <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">{error}</div>}
        <div className="space-y-4">
          <input
            type="text"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-hxmPink-500"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-hxmPink-500"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600 hover:text-hxmPink-500 transition-colors duration-300"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <button
            className={`flex items-center justify-center w-12 h-12 bg-hxmBlue-500 text-white rounded-full hover:bg-hxmBlue-600 transition-colors duration-300 ${isShaking ? 'animate-shake' : ''}`}
            onClick={handleLogin}
          >
            {isUnlocked ? <CiUnlock className="text-3xl" /> : <CiLock className="text-3xl" />}
          </button>
        </div>
        <button
          className="absolute bottom-4 left-5 text-gray-500 hover:text-hxmPink-500 transition-colors duration-300"
          onClick={() => navigate('/')}
        >
          <BiHomeAlt2 className="text-xl" />
        </button>
      </div>
    </div>
  );
}

export default Login;
