import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from './AdminSidebar';
import { FaTrashAlt, FaEllipsisV, FaUser } from 'react-icons/fa';
import { IoIosAdd } from 'react-icons/io';
import { RiAdminFill } from 'react-icons/ri';
import { BiArrowBack } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';
import { RxCross2 } from 'react-icons/rx';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [role, setRole] = useState('admin');
  const [activeMenu, setActiveMenu] = useState(null);
  const [showUserInfo, setShowUserInfo] = useState(null);
  const [showCreateUserPopup, setShowCreateUserPopup] = useState(false);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('user');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserFirstName, setNewUserFirstName] = useState('');
  const [newUserLastName, setNewUserLastName] = useState('');

  const navigate = useNavigate();
  const menuRef = useRef(null);
  const userPopupRef = useRef(null);
  const createUserPopupRef = useRef(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/login');
      } else {
        setIsLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/users`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setUsers(response.data);
        } catch (error) {
          console.error('Error fetching users:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchUsers();
  }, [navigate]);

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('chatSidebarOpen', JSON.stringify(!sidebarOpen));
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem('chatSidebarOpen');
    if (savedSidebarState !== null) {
      setSidebarOpen(JSON.parse(savedSidebarState));
    }
  }, []);

  const handleMoreUserInfo = (user) => {
    setShowUserInfo(user);
  };

  const closeMoreUserInfo = () => {
    setShowUserInfo(null);
  };

  const toggleMenu = (userId) => {
    setActiveMenu(activeMenu === userId ? null : userId);
  };

  const handleCreateUser = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/create`, {
          username: newUserName,
          password: newUserPassword,
          first_name: newUserFirstName,
          last_name: newUserLastName,
          role: newUserRole,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setUsers([...users, response.data]);
        setNewUserName('');
        setNewUserEmail('');
        setNewUserPassword('');
        setNewUserFirstName('');
        setNewUserLastName('');
        setNewUserRole('user');
        setShowCreateUserPopup(false);
      } catch (error) {
        console.error('Error creating user:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  const handleUpdateUser = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/auth/users/${showUserInfo.username}`, {
          first_name: showUserInfo.profile.first_name,
          last_name: showUserInfo.profile.last_name,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setUsers(users.map(user => user.username === showUserInfo.username ? {
          ...user,
          profile: {
            ...user.profile,
            first_name: showUserInfo.profile.first_name,
            last_name: showUserInfo.profile.last_name,
          },
        } : user));
        setShowUserInfo(null);
      } catch (error) {
        console.error('Error updating user:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };    

  const handleDeleteUser = async (username) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoading(true);
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/auth/users/${username}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUsers(users.filter(user => user.username !== username));
        setActiveMenu(null);
      } catch (error) {
        console.error('Error deleting user:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
      if (userPopupRef.current && !userPopupRef.current.contains(event.target)) {
        closeMoreUserInfo();
      }
      if (createUserPopupRef.current && !createUserPopupRef.current.contains(event.target)) {
        setShowCreateUserPopup(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex h-screen overflow-hidden font-sans bg-gray-50">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        handleLogout={handleLogout}
        role={role}
      />
      <div className={`flex-grow p-6 transition-all duration-300 ${sidebarOpen ? 'ml-64' : 'ml-20'} overflow-auto`}>
        <div className="max-w-2xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-600 mb-4">Gestion des utilisateurs</h2>
          <div className="flex items-center mb-6 hover:text-hxmBlue-700">
                <BiArrowBack className="text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/dashboard')} />
                <span className="ml-2 text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/dashboard')}>Retour dashboard admin</span>
              </div>
          <div className="flex justify-end mb-6">
            <button
              onClick={() => setShowCreateUserPopup(true)}
              className="border-2 border-hxmBlue-500 text-hxmBlue-500 p-1 rounded-lg hover:bg-hxmBlue-100 transition-colors duration-300"
            >
              <IoIosAdd className="inline-block mr-2" /> Add User
            </button>
          </div>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div className="mb-6">
              <ul className="mt-2 space-y-2">
                {users.map(user => (
                  <li key={user._id} className="p-2 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300" onClick={() => handleMoreUserInfo(user)}>
                    <div className="flex items-center">
                      {user.role === 'admin' ? (
                        <RiAdminFill className="mr-2 text-hxmBlue-500 text-xl" />
                      ) : (
                        <FaUser className="mr-2 text-hxmBlue-500 text-lg" />
                      )}
                      <div>
                        <span className="text-md text-gray-700">{user.username}</span>
                        <p className="text-xs text-gray-500">{user.role}</p>
                      </div>
                    </div>
                    <div className="relative">
                      <FaEllipsisV className="cursor-pointer" onClick={(e) => {
                        e.stopPropagation();
                        toggleMenu(user._id);
                      }} />
                      {activeMenu === user._id && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 z-50" ref={menuRef}>
                          <button className="w-full text-left px-4 py-2 text-sm text-hxmBlue-500 hover:bg-gray-100" onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteUser(user.username);
                          }}>
                            <FaTrashAlt className="mr-2" /> Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
                {showUserInfo && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={userPopupRef}>
                      <RxCross2 className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmPink-500" onClick={closeMoreUserInfo} />
                      <h2 className="text-xl font-bold mb-4">Information de profile</h2>
                      <p className="text-gray-700 mb-2">Prénom</p>
                      <input
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                        value={showUserInfo.profile.first_name}
                        onChange={(e) => setShowUserInfo({ ...showUserInfo, profile: { ...showUserInfo.profile, first_name: e.target.value } })}
                        />
                      <p className="text-gray-700 mb-2">Nom</p>
                      <input
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                        value={showUserInfo.profile.last_name}
                        onChange={(e) => setShowUserInfo({ ...showUserInfo, profile: { ...showUserInfo.profile, last_name: e.target.value } })}
                        />
                      <button
                        onClick={handleUpdateUser}
                        className="w-full bg-hxmBlue-500 text-white p-2 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300"
                      >
                        Update User
                      </button>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      {showCreateUserPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={createUserPopupRef}>
            <RxCross2
              className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmPink-500"
              onClick={() => setShowCreateUserPopup(false)}
            />
            <h2 className="text-xl font-bold mb-4">Create New User</h2>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              placeholder="Username"
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
            />
            <input
              type="password"
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              placeholder="Password"
              value={newUserPassword}
              onChange={(e) => setNewUserPassword(e.target.value)}
            />
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              placeholder="First Name"
              value={newUserFirstName}
              onChange={(e) => setNewUserFirstName(e.target.value)}
            />
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              placeholder="Last Name"
              value={newUserLastName}
              onChange={(e) => setNewUserLastName(e.target.value)}
            />
            <select
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              value={newUserRole}
              onChange={(e) => setNewUserRole(e.target.value)}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
            <button
              onClick={handleCreateUser}
              className="w-full bg-hxmBlue-500 text-white p-2 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300"
            >
              Create User
            </button>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-25">
          <CgSpinner className="w-6 h-6 text-hxmBlue-500 animate-spin text-3xl" />
        </div>
      )}
    </div>
  );
}

export default UserManagement;
