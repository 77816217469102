import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { IoIosAdd } from 'react-icons/io';
import { BiArrowBack } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';
import { FaEllipsisV } from 'react-icons/fa';
import { BsCollection } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import Sidebar from './AdminSidebar';

function GlossaryManagement() {
  const [folders, setFolders] = useState([]);
  const [glossaries, setGlossaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [role, setRole] = useState('admin');
  const [showCreateGlossaryPopup, setShowCreateGlossaryPopup] = useState(false);
  const [newGlossaryName, setNewGlossaryName] = useState('');
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState(null); // State to hold selected folder for glossary creation

  const navigate = useNavigate();
  const createGlossaryPopupRef = useRef(null);

  useEffect(() => {
    const fetchFolders = async () => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/login');
      } else {
        setIsLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/folders`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setFolders(response.data);
        } catch (error) {
          console.error('Error fetching folders:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchFolders();
  }, [navigate]);

  const fetchGlossaries = async (folderId) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/${folderId}/glossaries`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setGlossaries(response.data);
      } catch (error) {
        console.error('Error fetching glossaries:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('chatSidebarOpen', JSON.stringify(!sidebarOpen));
  };

  const handleCreateGlossary = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/folders/${selectedFolderId}/glossary`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          params: {
            name: newGlossaryName,
          },
        });

        setGlossaries([...glossaries, response.data]);
        setNewGlossaryName('');
        setSelectedFolderId(null); // Reset the selected folder after creation
        setShowCreateGlossaryPopup(false);
      } catch (error) {
        console.error('Error creating glossary:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  const handleFolderClick = (folderId) => {
    setCurrentFolderId(folderId);
    fetchGlossaries(folderId);
  };

  const handleSelectFolderForGlossary = (folderId) => {
    setSelectedFolderId(folderId);
  };

  const handleGlossaryClick = (glossaryId) => {
    console.log('glossaryId:', glossaryId);
    navigate(`/admin/glossaries/${glossaryId}`);
  };

  const handleDeleteGlossary = async (glossaryId) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/glossary/${glossaryId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        if (response.data) {
          setGlossaries(glossaries.filter(glossary => glossary._id !== glossaryId));
        }
      } catch (error) {
        console.error('Error deleting glossary:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  return (
    <div className="flex h-screen overflow-hidden font-sans bg-gray-50">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        handleLogout={handleLogout}
        role={role}
      />
      <div className={`flex-grow p-6 transition-all duration-300 ${sidebarOpen ? 'ml-64' : 'ml-20'} overflow-auto`}>
        <div className="max-w-2xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-600 mb-4">Glossary Management</h2>
          {!currentFolderId && (
            <>
              <div className="flex items-center mb-6 hover:text-hxmBlue-700">
                <BiArrowBack className="text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/dashboard')} />
                <span className="ml-2 text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/dashboard')}>Back to Admin Dashboard</span>
              </div>
              <div className="flex justify-end mb-6">
                <button
                  onClick={() => setShowCreateGlossaryPopup(true)}
                  className="border-2 border-hxmBlue-500 text-hxmBlue-500 p-1 rounded-lg hover:bg-hxmBlue-100 transition-colors duration-300"
                >
                  <IoIosAdd className="inline-block mr-2" /> Add Glossary
                </button>
              </div>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <div className="mb-6">
                  <ul className="mt-2 space-y-2">
                    {folders.map(folder => (
                      <li 
                        key={folder._id} 
                        className="p-2 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300" 
                        onClick={() => handleFolderClick(folder._id)}
                      >
                        <div className="flex items-center">
                          <BsCollection className="mr-2 text-hxmBlue-500" />
                          <span>{folder.name}</span>
                        </div>
                        <FaEllipsisV className="cursor-pointer hover:text-hxmPink-500" />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
          {currentFolderId && (
            <>
              <div className="flex items-center mb-6 hover:text-hxmBlue-700">
                <BiArrowBack className="text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => setCurrentFolderId(null)} />
                <span className="ml-2 text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => setCurrentFolderId(null)}>Back to Folders</span>
              </div>
              <div className="flex items-center mb-5">
                <BsCollection className="mr-4 text-2xl text-hxmBlue-500" />
                <h3 className="text-3xl font-medium text-gray-600">{folders.find(folder => folder._id === currentFolderId)?.name}</h3>
              </div>
              {glossaries.length > 0 ? (
                <div className="mb-6">
                  <ul className="mt-2 space-y-2">
                    {glossaries.map(glossary => (
                      <li key={glossary._id} className="p-2 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300">
                        <div onClick={() => handleGlossaryClick(glossary._id)}>
                          <span className="text-md text-gray-700">{glossary.name}</span>
                        </div>
                        <RxCross2
                          className="text-xl cursor-pointer text-gray-600 hover:text-hxmPink-500"
                          onClick={() => handleDeleteGlossary(glossary._id)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="text-center text-gray-600">No glossaries found in this folder</div>
              )}
            </>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-25">
          <CgSpinner className="w-6 h-6 text-hxmBlue-500 animate-spin text-3xl" />
        </div>
      )}
      {showCreateGlossaryPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={createGlossaryPopupRef}>
            <RxCross2
              className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmPink-500"
              onClick={() => setShowCreateGlossaryPopup(false)}
            />
            <h2 className="text-xl font-bold mb-4">Create New Glossary</h2>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              placeholder="Glossary Name"
              value={newGlossaryName}
              onChange={(e) => setNewGlossaryName(e.target.value)}
            />
            <h3 className="text-lg font-medium text-gray-700 mb-2">Select Folder</h3>
            <div className="grid grid-cols-2 gap-4">
              {folders.map(folder => (
                <div
                  key={folder._id}
                  className={`p-4 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer ${selectedFolderId === folder._id ? 'border-2 border-hxmBlue-500' : ''}`}
                  onClick={() => handleSelectFolderForGlossary(folder._id)}
                >
                  <span>{folder.name}</span>
                </div>
              ))}
            </div>
            <button
              onClick={handleCreateGlossary}
              className="w-full bg-hxmBlue-500 text-white p-2 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300 mt-4"
              disabled={!newGlossaryName || !selectedFolderId} // Disable button if no name or folder is selected
            >
              Create Glossary
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GlossaryManagement;
