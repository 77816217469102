import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { IoIosAdd } from 'react-icons/io';
import { BiArrowBack } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';
import { RxCross2 } from 'react-icons/rx';
import Sidebar from './AdminSidebar';

function GlossaryList() {
  const [glossaries, setGlossaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [role, setRole] = useState('admin');
  const [showCreateGlossaryPopup, setShowCreateGlossaryPopup] = useState(false);
  const [newGlossaryName, setNewGlossaryName] = useState('');
  
  const navigate = useNavigate();
  const { folderId } = useParams();
  
  useEffect(() => {
    const fetchGlossaries = async () => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/login');
      } else {
        setIsLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/${folderId}/glossaries`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setGlossaries(response.data);
        } catch (error) {
          console.error('Error fetching glossaries:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchGlossaries();
  }, [folderId, navigate]);
  
  const handleCreateGlossary = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/glossaries`, {
          name: newGlossaryName,
          folderId
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setGlossaries([...glossaries, response.data]);
        setNewGlossaryName('');
        setShowCreateGlossaryPopup(false);
      } catch (error) {
        console.error('Error creating glossary:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };
  
  return (
    <div className="flex h-screen overflow-hidden font-sans bg-gray-50">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        handleLogout={() => {
          Cookies.remove('token');
          navigate('/');
        }}
        role={role}
      />
      <div className={`flex-grow p-6 transition-all duration-300 ${sidebarOpen ? 'ml-64' : 'ml-20'} overflow-auto`}>
        <div className="max-w-2xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-600 mb-4">Glossaries in Folder</h2>
          <div className="flex items-center mb-6 hover:text-hxmBlue-700">
            <BiArrowBack className="text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/glossaries')} />
            <span className="ml-2 text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700" onClick={() => navigate('/admin/glossaries')}>Retour aux collections</span>
          </div>
          <div className="flex justify-end mb-6">
            <button
              onClick={() => setShowCreateGlossaryPopup(true)}
              className="border-2 border-hxmBlue-500 text-hxmBlue-500 p-1 rounded-lg hover:bg-hxmBlue-100 transition-colors duration-300"
            >
              <IoIosAdd className="inline-block mr-2" /> Add Glossary
            </button>
          </div>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div className="mb-6">
              <ul className="mt-2 space-y-2">
                {glossaries.map(glossary => (
                  <li key={glossary.id} className="p-2 bg-gray-100 rounded-lg flex justify-between items-center cursor-pointer hover:bg-hxmBlue-200 transition-colors duration-300" onClick={() => navigate(`/admin/glossaries/${glossary.id}`)}>
                    <div className="flex items-center">
                      <span className="text-md text-gray-700">{glossary.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-25">
          <CgSpinner className="w-6 h-6 text-hxmBlue-500 animate-spin text-3xl" />
        </div>
      )}
      {showCreateGlossaryPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <RxCross2
              className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmPink-500"
              onClick={() => setShowCreateGlossaryPopup(false)}
            />
            <h2 className="text-xl font-bold mb-4">Create New Glossary</h2>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              placeholder="Glossary Name"
              value={newGlossaryName}
              onChange={(e) => setNewGlossaryName(e.target.value)}
            />
            <button
              onClick={handleCreateGlossary}
              className="w-full bg-hxmBlue-500 text-white p-2 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300"
            >
              Create Glossary
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GlossaryList;
