import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { IoIosAdd } from 'react-icons/io';
import { BiArrowBack } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';
import { RxCross2 } from 'react-icons/rx';

function GlossaryDetail() {
  const [glossary, setGlossary] = useState(null);
  const [newTermWord, setNewTermWord] = useState('');
  const [newTermDefinition, setNewTermDefinition] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAddTermPopup, setShowAddTermPopup] = useState(false);
  
  const { glossaryId } = useParams();
  const navigate = useNavigate();
  const addTermPopupRef = useRef(null);

  useEffect(() => {
    const fetchGlossary = async () => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/login');
      } else {
        setIsLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/glossary/${glossaryId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setGlossary(response.data);
        } catch (error) {
          console.error('Error fetching glossary:', error);
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchGlossary();
  }, [glossaryId, navigate]);

  const handleAddTerm = async () => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/glossary/${glossaryId}/term`, {
          word: newTermWord,
          definition: newTermDefinition
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setGlossary(response.data);
        setNewTermWord('');
        setNewTermDefinition('');
        setShowAddTermPopup(false);
      } catch (error) {
        console.error('Error adding term:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  const handleDeleteTerm = async (termWord) => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/admin/glossary/${glossaryId}/term`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            term: termWord // Pass the term as a query parameter
          }
        });
        setGlossary(response.data); // Update glossary to remove the deleted term
      } catch (error) {
        console.error('Error deleting term:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addTermPopupRef.current && !addTermPopupRef.current.contains(event.target)) {
        setShowAddTermPopup(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex h-screen overflow-hidden font-sans bg-gray-50">
      <div className="flex-grow p-6 transition-all duration-300 ml-20 overflow-auto">
        <div className="max-w-2xl mx-auto">
          <div className="flex items-center mb-6 hover:text-hxmBlue-700">
            <BiArrowBack
              className="text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700"
              onClick={() => navigate('/admin/glossaries')}
            />
            <span
              className="ml-2 text-lg cursor-pointer text-hxmBlue-500 hover:text-hxmBlue-700"
              onClick={() => navigate('/admin/glossaries')}
            >
              Retour à la gestion des glossaires
            </span>
          </div>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div>
              {glossary && (
                <>
                  <h2 className="text-2xl font-bold text-gray-600 mb-4">{glossary.name}</h2>
                  <div className="flex justify-end mb-6">
                    <button
                      onClick={() => setShowAddTermPopup(true)}
                      className="border-2 border-hxmBlue-500 text-hxmBlue-500 p-1 rounded-lg hover:bg-hxmBlue-100 transition-colors duration-300"
                    >
                      <IoIosAdd className="inline-block mr-2" /> Add Term
                    </button>
                  </div>
                  <ul className="mt-2 space-y-2">
                    {glossary.terms.map(term => (
                      <li key={term.word} className="p-2 bg-gray-100 rounded-lg flex justify-between items-center">
                        <div>
                          <strong>{term.word}:</strong> {term.definition}
                        </div>
                        <RxCross2
                          className="text-xl cursor-pointer text-gray-600 hover:text-hxmPink-500"
                          onClick={() => handleDeleteTerm(term.word)}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          )}
          {showAddTermPopup && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" ref={addTermPopupRef}>
                <RxCross2
                  className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-600 hover:text-hxmPink-500"
                  onClick={() => setShowAddTermPopup(false)}
                />
                <h2 className="text-xl font-bold mb-4">Add Term to Glossary</h2>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                  placeholder="Word"
                  value={newTermWord}
                  onChange={(e) => setNewTermWord(e.target.value)}
                />
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                  placeholder="Definition"
                  value={newTermDefinition}
                  onChange={(e) => setNewTermDefinition(e.target.value)}
                />
                <button
                  onClick={handleAddTerm}
                  className="w-full bg-hxmBlue-500 text-white p-2 rounded-lg hover:bg-hxmBlue-600 transition-colors duration-300"
                >
                  Add Term
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-25">
          <CgSpinner className="w-6 h-6 text-hxmBlue-500 animate-spin text-3xl" />
        </div>
      )}
    </div>
  );
}

export default GlossaryDetail;
